@tailwind base;
@tailwind components;
@tailwind utilities;

/* disables box shadow border around input field */
input {
    --tw-ring-shadow: 0 0 #000 !important;
}

body {
    overscroll-behavior-y: none;
}

.pulse {
    animation: pulse 2s infinite;
}

@keyframes pulse {
    0% {
        opacity: 1;
    }
    70% {
        opacity: 0.70;
    }
    100% {
        opacity: 1;
    }
}

.custom-scrollbar::-webkit-scrollbar {
  width: 8px;
}

.custom-scrollbar::-webkit-scrollbar-track {
  background: #2d3748;
  border-radius: 4px;
}

.custom-scrollbar::-webkit-scrollbar-thumb {
  background-color: #4a5568;
  border-radius: 4px;
}

.custom-scrollbar::-webkit-scrollbar-thumb:hover {
  background-color: #718096;
}